import { Theme, defaultTokens } from '@qcx/ui';
import { colors } from './common';

export const light: Theme = {
  fonts: {
    sans: `${defaultTokens.fonts.sans}`,
    heading: `Archivo, ${defaultTokens.fonts.sans}`
  },
  colors,
  shadows: {
    focusRingPrimary: '0px 0px 0px 4px $colors$primary100'
  }
};
