import { Box, Button, Container, Icon, Text } from '@qcx/ui';
import { Footer } from '../components/shell/Footer';
import { Header } from '../components/shell/Header';
import { Link } from '@qcx/remix';
import { Navbar } from '../components/shell/Navbar';
import { Page } from './page/Page';
import { Suspense } from 'react';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { route } from '../route';
import { useNavigate } from '@remix-run/react';

interface ErrorScreenProps {
  status: number;
}

interface ErrorDataType {
  title: string;
  subtitle: string;
}

const Errors: Record<number, ErrorDataType> = {
  404: {
    title: 'Erro 404 - Página não encontrada',
    subtitle: 'A página que você está procurando não existe.'
  },
  503: {
    title: 'A página não foi carregada completamente',
    subtitle:
      'Por favor tente novamente recarregando ou volte para a página de início'
  }
};

export const ErrorScreen = ({ status }: ErrorScreenProps) => {
  const title = Errors[status]?.title ?? 'Ops, tivemos um problema';
  const subtitle =
    Errors[status]?.subtitle ??
    'Nossa equipe já foi notificada sobre este erro.';

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <>
      <Suspense>
        <Header />
        <Navbar />
      </Suspense>
      <Page>
        <Container
          as="section"
          css={{
            flexColCenter: true,
            justifyContent: 'center',
            height: 500
          }}
        >
          <Box
            css={{
              bg: '$blue25',
              w: '$16',
              h: '$16',
              flexCenter: true,
              borderRadius: 28
            }}
          >
            <Box
              css={{
                bg: '$blue100',
                w: '$12',
                h: '$12',
                flexCenter: true,
                borderRadius: 28
              }}
            >
              <Icon
                css={{ color: '$blue600' }}
                icon={faExclamationCircle}
                size="lg"
              />
            </Box>
          </Box>
          <Text
            as="h1"
            css={{ textAlign: 'center' }}
            variant="displayMd"
            weight="semibold"
          >
            {title}
          </Text>
          <Text
            as="h3"
            css={{ textAlign: 'center', mt: '$4', color: '$neutral500' }}
            variant="md"
            weight="regular"
          >
            {subtitle}
          </Text>
          <Text
            as="h3"
            css={{ textAlign: 'center', color: '$neutral500' }}
            variant="md"
            weight="regular"
          >
            Aqui estão alguns links úteis
          </Text>
          <Box
            css={{
              flexCol: true,
              gap: '$3',
              mt: '$8',
              '@md': { flexDirection: 'row-reverse', mt: '$12' }
            }}
          >
            <Button
              as={Link}
              aria-label="Ir para o início"
              to={route('home')}
              variant="primary"
            >
              Ir para o Início
            </Button>
            <Button
              css={{ w: 343, h: '$12', '@md': { w: '$44' } }}
              aria-label="Voltar"
              onClick={goBack}
            >
              Voltar
            </Button>
          </Box>
        </Container>
      </Page>
      <Suspense>
        <Footer />
      </Suspense>
    </>
  );
};
