import { Theme } from '@qcx/ui';

export const colors: Partial<Theme['colors']> = {
  primary25: '#fbfdfe',
  primary50: '#e5f4ff',
  primary100: '#e5f4ff',
  primary200: '#bde1ff',
  primary300: '#75bcff',
  primary400: '#248eff',
  primary500: '#0070fa',
  primary600: '#005dd6',
  primary700: '#0044a3',
  primary800: '#00398f',
  primary900: '#00265c',
  primary: '$primary600',
  secondary25: '#fff9f5',
  secondary50: '#fff4ed',
  secondary100: '#ffe6d5',
  secondary200: '#ffd6ae',
  secondary300: '#ff9c66',
  secondary400: '#ff692e',
  secondary500: '#ff4405',
  secondary600: '#e62e05',
  secondary700: '#bc1b06',
  secondary800: '#97180c',
  secondary900: '#771a0d',
  secondary: '$secondary500'
};
