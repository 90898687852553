import archivoCSS from '@fontsource-variable/archivo/wght.css?inline';
import interCSS from '@fontsource-variable/inter/wght.css?inline';
import { ASSETS_URL } from './lib/constants/url.lib';
import { AuthModalStoreProvider } from '@kratos/auth';
import { EditorialsStoreProvider } from './pages/editorials/editorials.store';
import { ErrorScreen } from './components/ErrorScreen';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  ShouldRevalidateFunction,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError
} from '@remix-run/react';
import { LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import { PageLoadingIndicator, Script, isServer } from '@qcx/remix';
import { ReactNode, Suspense } from 'react';
import {
  ShellStoreProvider,
  useShellStore
} from './components/shell/shell.store';
import { UIAppProvider, UIHeadElements, UIProvider, useMounted } from '@qcx/ui';
import { dark, light } from './styles/theme';
import { getPrivateEnvVars, getPublicEnvVars } from './lib/env.lib';

const theme = { light, dark };

export const shouldRevalidate: ShouldRevalidateFunction = () => false;

export const links: LinksFunction = () => [
  {
    rel: 'icon',
    href: `${ASSETS_URL}/images/qconcursos/favicon.ico`,
    type: 'image/x-icon'
  }
];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  if (!['GET', 'HEAD'].includes(request.method.toUpperCase())) {
    throw new Response('Method not allowed', { status: 204 });
  }

  const url = new URL(request.url);

  return {
    env: getPublicEnvVars(),
    url
  };
};

const AppShell = ({
  env,
  children
}: {
  env: PublicEnvVars;
  children: ReactNode;
}) => (
  <ShellStoreProvider>
    <UIAppProvider
      imageConfig={{
        loaderPreset: 'cloudflare-image-resizing',
        loaderOptions: {
          url: 'https://image-resize.qconcursos.com'
        }
      }}
    >
      <html className="light" lang="pt-br">
        <head>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1,viewport-fit=cover"
          />
          <Meta />
          <style
            dangerouslySetInnerHTML={{
              __html: `${interCSS}${archivoCSS}`
            }}
          />
          <Links />
          <UIHeadElements />
        </head>
        <body>
          <UIProvider theme={theme}>
            <Suspense>
              <PageLoadingIndicator />
            </Suspense>
            <AuthModalStoreProvider>
              <EditorialsStoreProvider>{children}</EditorialsStoreProvider>
            </AuthModalStoreProvider>
          </UIProvider>
          <EnvScript env={env} />
          <ScrollRestoration />
          <Scripts />
          <ThirdPartyScripts />
        </body>
      </html>
    </UIAppProvider>
  </ShellStoreProvider>
);

const App = () => {
  const { env } = useLoaderData<typeof loader>();

  return (
    <AppShell env={env}>
      <Outlet />
    </AppShell>
  );
};

const EnvScript = ({ env }: { env: PublicEnvVars }) => {
  return (
    <Script
      dangerouslySetInnerHTML={{
        __html: `self.ENV = ${JSON.stringify(env)}`
      }}
      strategy="normal"
    />
  );
};

const ThirdPartyScripts = () => {
  return (
    <>
      <ZarazScript />
    </>
  );
};

const ZarazScript = () => {
  const env = getPublicEnvVars();
  const handleZarazLoad = useShellStore(s => s.setZarazFetched);

  useMounted(() => {
    // Ensure zaraz loaded event is always called in development.
    if (env.PUBLIC_APP_ENV === 'development') {
      handleZarazLoad();
    }
  });

  return env.PUBLIC_APP_ENV === 'development' ? null : (
    <Script
      referrerPolicy="origin"
      src="/cdn-cgi/zaraz/i.js"
      strategy="lazyOnload"
      onLoad={handleZarazLoad}
    />
  );
};

export const ErrorBoundary = () => {
  const env = getPublicEnvVars();
  const { PUBLIC_APP_VERSION, PUBLIC_APP_ENV } = env;
  const error = useRouteError();
  const isRouteResponseError = isRouteErrorResponse(error);
  const status = isRouteResponseError ? error.status : 500;

  if (PUBLIC_APP_ENV !== 'development' && isServer && !isRouteResponseError) {
    import('rollbar').then(mod => {
      const { PRIVATE_ROLLBAR_ACCESS_TOKEN } = getPrivateEnvVars();
      const Rollbar = mod.default;
      const rollbar = new Rollbar({
        accessToken: PRIVATE_ROLLBAR_ACCESS_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        environment: PUBLIC_APP_ENV,
        payload: {
          environment: PUBLIC_APP_ENV,
          client: {
            javascript: {
              source_map_enabled: true,
              code_version: PUBLIC_APP_VERSION,
              guess_uncaught_frames: true
            }
          }
        }
      });

      rollbar.error(error as Error);
    });
  }

  return <AppShell env={env}>{getErrorComponent(status)}</AppShell>;
};

const getErrorComponent = (status: number) => <ErrorScreen status={status} />;

export default App;
